import './community.css';
import Leftmenu from '../../layout/leftmenu';
import React from 'react';
import withRouter from '../../components/withRouter';

import {requestPost} from '../../libs/request';
import Pagination from '../../components/Pagination';
import { updateAdminPerm, updateMyAlarm, formatDate, formatNum, toastMsg, getCurrentTime, renderPostCategory } from '../../libs/utils';
import consts from '../../libs/consts';
import NCommunityJobList from './NCommunityJobList';

class CommunityComment extends React.Component {
  constructor() {
    super();
    this.state = {
      keyword:      '',
      status:       '',
      totalCnt:     0,
      currentPage:  1,
      dataList:     [],
      dataList2:    [],
      checkAll:     false,
    };
  }

  componentDidMount = () => {
    // 
    updateAdminPerm();
    updateMyAlarm();

    const { searchParams } = this.props.router;
    const keyword  = searchParams.get("key");
    if(keyword) {
      this.setState({keyword});
    }
    setTimeout(() => {
      this.getData();
    }, 10);
  }

  getData = (currentPage=this.state.currentPage) => {
    window.scrollTo(0, 0);
    this.setState({currentPage});

    this.getReComments();
    // 
    const { keyword, status } = this.state;
    const params = { keyword, status, currentPage }
    requestPost('getCommentList', params, (res) => {
      if(Boolean(res)) {
        let totalCnt = 0;
        if(Boolean(res[0])) {
          totalCnt = res[0].total_cnt;
        }
        this.setState({dataList: res, totalCnt});
      }
    });
  }
  getReComments = () => {
    const { keyword, status } = this.state;
    const params = { keyword, status }
    requestPost('getReCommentList', params, (res) => {
      if(Boolean(res)) {
        this.setState({dataList2: res});
      }
    });
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.getData(1);
    }
  }
  changeStatus = (status) => {
    this.setState({status})
    setTimeout(() => {
      this.getData(1);
    }, 10);
  }

  gotoPage = (link) => {
    const { router } = this.props;
    router.navigate(link);
  }

  render() {
    const { keyword, status, totalCnt, currentPage, dataList, dataList2 } = this.state;

    let dataList3 = [];   // 현재 렌더중인 댓글의 대댓글
    dataList.map((com, index) => {
      dataList2.map((recom, idx) => {
        if(com.id == recom.comment_id) {
          dataList3.push(recom);
        }
      })
    })
    return (
      <Leftmenu>
        <NCommunityJobList/>
        {/* <div className='page_contents cmtlist'>
          <div className='card'>
            <div className='card_head'>
              <div className='card_head_title'>댓글/대댓글</div>
              <div className='card_head_tool'>
                <div className='search_input'>
                  <input type="text" placeholder='게시글ID, 제목, 작성자 닉네임으로 검색'
                    value={keyword} 
                    onKeyUp={this.handleKeyPress} 
                    onChange={e => this.setState({keyword: e.target.value}) } 
                  />
                  <div className='search_btn' onClick={() => this.getData(1)} />
                </div>
              </div>
            </div>
            <div className='card_head'>
              <div className='table_total'>
              <span>{formatNum(totalCnt + dataList3.length)}</span>개의 게시글
              </div>
              <div className='fralign_center' style={{ gap: 20 }}>
                <div className='font_D700_14'>노출상태</div>
                  <label className='radio_label' onClick={() => this.changeStatus(0)}><input type="radio" name="status" checked={status == 0} onChange={()=>{}} />전체</label>
                  <label className='radio_label' onClick={() => this.changeStatus(1)}><input type="radio" name="status" checked={status == 1} onChange={()=>{}} />공개</label>
                  <label className='radio_label' onClick={() => this.changeStatus(2)}><input type="radio" name="status" checked={status == 2} onChange={()=>{}} />비공개</label>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>원게시글ID</th>
                  <th>댓글ID</th>
                  <th>대댓글ID</th>
                  <th>내용</th>
                  <th>작성자</th>
                  <th>노출상태</th>
                  <th>작성일시</th>
                </tr>
              </thead>
              <tbody>
                {dataList.length > 0 ?
                  dataList.map((item, index) => 
                  <>
                    <tr key={index} onClick={() => this.gotoPage('/community/comment/detail?id=' + item.id)}>
                        <td>{item.target_id}</td>
                        <td>{item.id}</td>
                        <td>-</td>
                        <td className='ellipsis1'>{Boolean(item.comment) && item.comment.length > consts.showMaxLen ? item.comment.substr(0, consts.showMaxLen)+'...' : item.comment}</td>
                        <td>{item.nickname}</td>
                        <td>{item.status == 1 ? '공개' : (item.status == 2 ? '비공개(사용자삭제)' : '비공개(블라인드)') }</td>
                        <td>{formatDate(item.reg_time)}</td>
                    </tr>
                    {dataList3.map((item2, idx) => 
                        item.id == item2.comment_id &&
                        <tr key={index+ '-' + idx} onClick={() => this.gotoPage('/community/comment/detail?id=' + item.id)}>
                          <td>{item.target_id}</td>
                          <td>{item.id}</td>
                          <td>{item2.id}</td>
                          <td className='ellipsis1'>{Boolean(item2.comment) && item2.comment.length > consts.showMaxLen ? item2.comment.substr(0, consts.showMaxLen)+'...' : item2.comment}</td>
                          <td>{item2.nickname}</td>
                          <td>{item2.status == 1 ? '공개' : (item2.status == 2 ? '비공개(사용자삭제)' : '비공개(블라인드)') }</td>
                          <td>{formatDate(item2.reg_time)}</td>
                        </tr>
                    )}
                  </>
                  )
                  :
                  <tr><td colSpan={7}>데이터가 없습니다.</td></tr>
                  }
              </tbody>
            </table>
            <Pagination totalCnt={totalCnt} onClick={(pageIdx) => this.getData(pageIdx)} currentPage={currentPage} />
          </div>
        </div> */}
      </Leftmenu>
    );
  }
}
export default withRouter(CommunityComment);