/* eslint-disable no-shadow */
import axios  from 'axios';
import consts from '../libs/consts';

const Axios = axios.create({
  baseURL: `${consts.apiBasicUrl}`,
  timeout: 300000,
});

Axios.interceptors.request.use(
  (config ) => {
    const user_id     = localStorage.getItem('user_id');
    const auth_token  = localStorage.getItem('auth_token');
    config.headers = {
      'x-access-token': `${auth_token}`,
      'x-access-id': `${user_id}`,
      'Content-type':  'application/json',
    };
    return config;
  },
  (error) => Promise.reject(error)
);

export default Axios;
