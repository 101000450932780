import { useMemo, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import { requestFile } from "../../libs/request";
import consts from "../../libs/consts";

import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";

Quill.register("modules/imageActions", ImageActions);
Quill.register("modules/imageFormats", ImageFormats);

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "align",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "background",
  "color",
  "link",
  "image",
  "width",
  "height",
];

const QuillEditor = ({ handleChangeEditor, content }) => {
  const quillRef = useRef(null);
  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;

      console.log(file);
      if (!file) return;

      const editor = quillRef.current?.getEditor();
      const range = editor?.getSelection() ?? false;
      if (!range) return;
      const formData = new FormData();
      formData.append("file", file);
      let path = "";
      requestFile("uploadImage", formData, (res) => {
        console.log("res");
        console.log(res);

        const { file_path } = res;

        path = consts.resourcUrl + file_path;
        editor?.insertEmbed(range.index, "image", path);
        editor?.setSelection({
          index: range.index + 1,
          length: range.length + 1,
        });
      });
    };
  };
  const modules = useMemo(() => {
    return {
      imageActions: {},
      imageFormats: {},
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ align: [] }],
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            "link",
            { indent: "-1" },
            { indent: "+1" },
          ],
          [
            {
              color: [
                "#000000",
                "#e60000",
                "#ff9900",
                "#ffff00",
                "#008a00",
                "#0066cc",
                "custom-color",
              ],
            },
            { background: [] },
          ],
          ["image"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
        ImageResize: {
          modules: ["Resize"],
        },
      },
    };
  }, []);

  return (
    <ReactQuill
      theme="snow"
      ref={quillRef}
      modules={modules}
      onChange={(v) => {
        handleChangeEditor(v);
      }}
      value={content}
      formats={formats}
    />
  );
};

export default QuillEditor;
