import { useSelector } from 'react-redux';

import './menu.css';
import Menugroup from '../menugroup';
import Menuitem from '../menuitem';
import { checkPerm } from '../../libs/utils';

function Menu() {
  const { perms } = useSelector((state) => state.authacc);

  return (
    <div className="menu_root">
      <Menugroup
        title={'대시보드'}
        linkurl={'/dashboard'}
        baseurl={'/dashboard'}
      />
      {(checkPerm(perms, '00') || checkPerm(perms, '01')) && (
        <Menugroup
          title={'교육관리'}
          linkurl={'/education/leasson'}
          baseurl={'/education'}
        >
          {checkPerm(perms, '00') && (
            <Menuitem title={'교육상품'} linkurl={'/education/leasson'} />
          )}
          {checkPerm(perms, '01') && (
            <Menuitem title={'키워드'} linkurl={'/education/keyword'} />
          )}
          {checkPerm(perms, '01') && (
            <Menuitem title={'큐레이션'} linkurl={'/education/curation'} />
          )}
          {checkPerm(perms, '01') && (
            <Menuitem title={'직무별 부트캠프'} linkurl={'/education/camp'} />
          )}
        </Menugroup>
      )}
      {(checkPerm(perms, '10') ||
        checkPerm(perms, '11') ||
        checkPerm(perms, '12') ||
        checkPerm(perms, '13')) && (
        <Menugroup
          title={'파트너사'}
          linkurl={'/partner/company'}
          baseurl={'/partner'}
        >
          {checkPerm(perms, '10') && (
            <Menuitem title={'전체 파트너사'} linkurl={'/partner/company'} />
          )}
          {checkPerm(perms, '11') && (
            <Menuitem
              title={'외부 파트너사 신청'}
              linkurl={'/partner/request'}
            />
          )}
          {checkPerm(perms, '12') && (
            <Menuitem title={'파트너계정관리'} linkurl={'/partner/manager'} />
          )}
          {checkPerm(perms, '13') && (
            <Menuitem title={'한줄톡'} linkurl={'/partner/linetok'} />
          )}
        </Menugroup>
      )}
      {(checkPerm(perms, '20') || checkPerm(perms, '21')) && (
        <Menugroup title={'회원'} linkurl={'/member/list'} baseurl={'/member'}>
          {checkPerm(perms, '20') && (
            <Menuitem title={'회원정보'} linkurl={'/member/list'} />
          )}
          {checkPerm(perms, '21') && (
            <Menuitem title={'탈퇴회원정보'} linkurl={'/member/outlist'} />
          )}
        </Menugroup>
      )}
      {(checkPerm(perms, '30') ||
        checkPerm(perms, '31') ||
        checkPerm(perms, '32') ||
        checkPerm(perms, '33')) && (
        <Menugroup title={'CRM'} linkurl={'/crm/queryprod'} baseurl={'/crm'}>
          {checkPerm(perms, '30') && (
            <Menuitem title={'상품문의'} linkurl={'/crm/queryprod'} />
          )}
          {checkPerm(perms, '31') && (
            <Menuitem title={'서비스 의견'} linkurl={'/crm/querysvc'} />
          )}
          {checkPerm(perms, '32') && (
            <Menuitem title={'평가 및 리뷰'} linkurl={'/crm/reviewer'} />
          )}
          {checkPerm(perms, '33') && (
            <Menuitem title={'게시글/사용자신고'} linkurl={'/crm/report'} />
          )}
          {checkPerm(perms, '32') && (
            <Menuitem title={'수강후기'} linkurl={'/crm/reviews'} />
          )}
          {checkPerm(perms, '32') && (
            <Menuitem
              title={'프리미엄 수강후기'}
              linkurl={'/crm/premiumReviews'}
            />
          )}
          {checkPerm(perms, '32') && (
            <Menuitem
              title={'매칭필요 수강후기'}
              linkurl={'/crm/unmatchedReviews'}
            />
          )}
        </Menugroup>
      )}
      {/* {(checkPerm(perms, '40') || checkPerm(perms, '41')) && (
        <Menugroup
          title={'커뮤니티'}
          linkurl={'/community/list'}
          baseurl={'/community'}
        >
          {checkPerm(perms, '40') && (
            <Menuitem title={'게시글'} linkurl={'/community/list'} />
          )}
          {checkPerm(perms, '41') && (
            <Menuitem title={'댓글/대댓글'} linkurl={'/community/comment'} />
          )}
        </Menugroup>
      )} */}
       {(checkPerm(perms, '40') || checkPerm(perms, '41')) && (
        <Menugroup
          title={'게시글'}
          linkurl={'/community/pickit'}
          baseurl={'/community'}
        >
          {checkPerm(perms, '40') && (
            <Menuitem title={'피킷이야기'} linkurl={'/community/pickit'} />
          )}
          {checkPerm(perms, '41') && (
            <Menuitem title={'취준이야기'} linkurl={'/community/job'} />
          )}
        </Menugroup>
      )}
      {(checkPerm(perms, '50') ||
        checkPerm(perms, '51') ||
        checkPerm(perms, '52')) && (
        <Menugroup
          title={'배너와 광고'}
          linkurl={'/banner/main'}
          baseurl={'/banner'}
        >
          {checkPerm(perms, '50') && (
            <Menuitem
              title={'홈 케러셀 (구 메인배너)'}
              linkurl={'/banner/main'}
            />
          )}
          {checkPerm(perms, '51') && (
            <Menuitem title={'광고 배너'} linkurl={'/banner/advert'} />
          )}
          {checkPerm(perms, '52') && (
            <Menuitem title={'광고 상품 설정'} linkurl={'/banner/setting'} />
          )}
        </Menugroup>
      )}
      {(checkPerm(perms, '60') ||
        checkPerm(perms, '61') ||
        checkPerm(perms, '62')) && (
        <Menugroup
          title={'기타 콘텐츠'}
          linkurl={'/other/jobpaper'}
          baseurl={'/other'}
        >
          {checkPerm(perms, '60') && (
            <Menuitem title={'직무 백서'} linkurl={'/other/jobpaper'} />
          )}
          {checkPerm(perms, '61') && (
            <Menuitem title={'이벤트'} linkurl={'/other/event'} />
          )}
          {/* {checkPerm(perms, '62') && <Menuitem title={"공지사항"} linkurl={"/other/notice"}/>} */}
        </Menugroup>
      )}
      {(checkPerm(perms, '70') || checkPerm(perms, '71')) && (
        <Menugroup
          title={'통계와 리포트'}
          linkurl={'/report/service'}
          baseurl={'/report'}
        >
          {checkPerm(perms, '70') && (
            <Menuitem title={'서비스이용통계'} linkurl={'/report/service'} />
          )}
          {checkPerm(perms, '71') && (
            <Menuitem title={'광고성과리포트'} linkurl={'/report/advert'} />
          )}
        </Menugroup>
      )}
      {(checkPerm(perms, '80') || checkPerm(perms, '81')) && (
        <Menugroup
          title={'관리자'}
          linkurl={'/manager/list'}
          baseurl={'/manager'}
        >
          {checkPerm(perms, '80') && (
            <Menuitem title={'관리자계정관리'} linkurl={'/manager/list'} />
          )}
          {checkPerm(perms, '81') && (
            <Menuitem
              title={'권한 그룹 관리'}
              linkurl={'/manager/permission'}
            />
          )}
        </Menugroup>
      )}
    </div>
  );
}

export default Menu;
